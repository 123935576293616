import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import './Services.css'; // Import your CSS file

const cardContent = [
  { title: 'Ανάπτυξη Ιστοσελίδων', 
   description: 'Παρέχουμε εξειδικευμένες υπηρεσίες ανάπτυξης ιστοσελίδων που προσαρμόζονται στις ανάγκες σας.',
   icon: "img/services/web.svg" },
  { title: 'Τεχνητή Νοημοσύνη', 
   description: ' Εφαρμόζουμε την τεχνητή νοημοσύνη για να παρέχουμε εξειδικευμένες λύσεις.',
   icon: "img/services/ai.svg" },
  { title: 'Διαδικτυακό Μαρκετίνκ', 
   description: 'Αναπτύσσουμε στρατηγικές διαδικτυακού μάρκετινγκ που ενισχύουν την προβολή και την αναγνωρισιμότητα του brand σας.',
   icon: "img/services/marketing.svg" },
  { title: 'Διαχείρηση Social Media', 
   description: 'Παρέχουμε ολοκληρωμένες υπηρεσίες διαχείρισης κοινωνικών δικτύων για να αυξήσουμε την προβολή σας στα social media.',
   icon: "img/services/social-media.svg" },
  { title: 'Διαχείρηση Πελατολογίου', 
   description: 'Παρέχουμε λύσεις διαχείρισης πελατολογίου που βελτιστοποιούν την επικοινωνία με τους πελάτες σας και αυξάνουν την ικανοποίησή τους.',
   icon: "img/services/customer-support.svg" },
  { title: 'Γραμματειακή Υποστήριξη', 
   description: 'Παρέχουμε εξειδικευμένες υπηρεσίες γραμματειακής υποστήριξης που ελαχιστοποιούν τον χρόνο και το κόστος σας σε διοικητικές διαδικασίες.',
   icon: "img/services/virtual-assistant.svg" },
];

function ServiceCard({ title, description, icon }) {
  return (
    <Card variant="outlined" className="card" sx={{ position: 'relative', minWidth: 275, marginBottom: 2 }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Box sx={{ marginBottom: 1 }}>
          <img src={icon} alt={title} style={{ width: '100px', height: '100px' }} />
        </Box>
        <Typography variant="h4" component="div">
          {title}
        </Typography>
        <Typography variant="body2" className="description" sx={{ color: 'black', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
          {description}
        </Typography>
      </CardContent>
      <div className="card-content">
        <Typography variant="body2" sx={{ color: 'black' }}>
          {description}
        </Typography>
      </div>
    </Card>
  );
}

function Services() {
  return (
    <div id="services" className="services-wrapper section-title">
      <h2 className="services">Υπηρεσιες</h2>
      <div className="cards">
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
          <Grid container spacing={2} sx={{ marginLeft: 0 }}>
            {cardContent.map((content, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ServiceCard
                  title={content.title}
                  description={content.description}
                  icon={content.icon}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default Services;
