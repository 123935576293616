import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>ΠΛΗΡΟΦΟΡΙΕΣ</h2>
              <p>Είμαστε μια ομάδα τελειόφοιτων, από διάφορα πολυτεχνεία και διακεκριμένα Πανεπιστήμια που κουράστηκαν με την κατάσταση της αγοράς εργασίας και θέλησαν να πρωτοπορήσουν στην ψηφιακή εποχή.</p>
              <h3>Γιατί να μας επιλέξετε;</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>Είμαστε οι φθηνότεροι στην αγορά</li>
                    <li>Είμαστε άρτια εκπαιδευμένοι στις σύγχονες τεχνολογίες</li>
                    
                    
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                  <li>Είμαστε νέοι με μεράκι που θέλουμε να σας βοηθήσουμε πραγματικά</li>
                  <li>Σε όλη την διάρκεια στης συνεργασίας θα είστε ενήμερος και δεν θα χρεωθείτε ποτέ για κάτι που δεν σας ικανοποιεί</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
